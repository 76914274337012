:root {
    --padding-radiobuttons-left: 3rem;
}

.RadioButton-Grouping {
    position: relative;
    margin-top: 0.5rem;
    padding: 1rem 1rem 1rem var(--padding-radiobuttons-left);
}

.RadioButton-Grouping .AdvancedRadioButton {
    margin-top: 0.7rem;
}

.overlay-spinner-radiobuttons {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    color: white;
    margin: auto calc(-1 * var(--padding-radiobuttons-left)) calc(-1 * var(--padding-radiobuttons-left));
    display: flex;
    font-size: 3rem;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.20);
    border-radius: 0.5rem;
}