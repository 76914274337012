.JunBots-Answers {
    margin-top: 50px;
    margin-bottom: 50px;
}

.JunBots-Answer {
    /* box-shadow: 10px 10px 18px #888888; */
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    border-radius: 10px;
    padding-left: 15px;
}

.JunBots-Answer-Header {
    margin-top: 1rem;
}