.App {
  background-color: rgb(59, 59, 59);
}

.Header {
  margin-bottom: 10px;
}

.Main {
  background-color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 15px;
}

.Page {
  padding: 0;
}

.Page-Content {
  width: 100%;
  padding: 0;
}

@media (min-width: 576px) {
  .Page-Content {
    max-width: min(90%, 900px);
  }
}

.JunBots-Card {
  box-shadow: 5px 5px 10px #888888;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 0;
}

.JunBots-Card-Body {
  margin-bottom: 0.5em;
}

.Footer {
  color: white;
  padding-top: 1.5em;
  padding-bottom: 1em;
  font-size: small;
}

.JunBots-FloatingElement {
  /* box-shadow: 10px 10px 18px #888888; */
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

iframe {
  width: min(100%, 458px);
  height: auto;
  aspect-ratio: 16/9;
  min-width: 0;
  min-height: 0;
}

.long-text {
  hyphens: auto;
}

.centering-container {
  margin: auto;
  width: auto;
}

.expandable {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
  aria-hidden: true;
}

a.main {
  color: black;
}
a:hover.main {
  color: black;
  font-weight: bold;
}


.rotating {
  animation: rotate 1.2s linear infinite both;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(365deg);
  }
}

:root {
  --radio-button-size: 1.1rem;
}
