.JunBots-Start-StartText {
    font-size: large;
    text-align: center;
    margin: 30px;
}

.JunBots-Start-Button {
    text-align: center;
}

.btn-danger {
    background-color: #6E1818;
}

.JunBots-Start-Info {
    max-width: 800px;
    text-align: justify;
}

.JunBots-Start-Videos-Headline {
    text-align: center;
    margin-top: 45px;
}

.JunBots-Video-List {
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.JunBots-Video {
    margin-left: auto;
    margin-right: auto;
    aspect-ratio: 16/9;
}

@media (min-width: 576px) {
    .JunBots-Video {
        max-width: 500px;
        margin-bottom: 1em;
    }
}

@media (max-width: 575px) {
    .JunBots-Video {
        margin-bottom: 0.5em;
    }
}

